import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Navbar from './components/Navbar';
import CodeEditorTyping from './components/CodeEditorTyping';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


function App() {
  const appStyles = {
    backgroundColor: '#333333',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    "overflowX": 'hidden',
  };

  const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    background: 'transparent'
  }));

  const [fadeRevealIntro, setFadeRevealIntro] = useState(0);
  const [isWideScreen, setIsWideScreen] = useState(true);
  const theme = useTheme();
  const isXsOrMd = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed

  useEffect(() => {
    async function animateSequentially() {
      await delay(1000); // Initial delay

      for (let i = 0; i <= 4; i++) {
        console.log(i)
        setFadeRevealIntro(i);
        await delay(1000); // Wait for the animation duration
      }
    }

    animateSequentially();
  }, []);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1700); // You can adjust this threshold
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [sequence, setSequence] = useState({});

  const updateSequence = (index, value) => {
    console.log(index, value)
    setSequence((prevSequence) => ({ ...prevSequence, [index]: value }));
  };

  return (
    <div style={appStyles}>
      <Navbar />
      <div className='introduction'>
        <Fade when={fadeRevealIntro >= 1}>
          <Grid container bgcolor="black" sx={{ height: '100vh', position: 'relative' }}>
            <Grid
              item
              xs={12}
              md={6}
              container
              justifyContent="flex-start"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: fadeRevealIntro >= 2 ? 1 : 0,
                ...(isXsOrMd && {
                  width: '100vw', // Make full width when opacity overlay is triggered
                }),
              }}
            >
              <Fade when={fadeRevealIntro >= 2} sx={{ width: '100%' }}>
                <Item>
                  <div
                    className="sunglasses-overlay"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: 2,
                      pointerEvents: 'none',
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                      opacity: isXsOrMd ? 1 : 0,
                      transition: 'opacity 0.5s ease',
                    }}
                  />
                  <img
                    src="/images/profile.jpg"
                    className="grid-image"
                    alt="Grid"
                    style={{
                      maxWidth: '100%',
                      height: '100vh',
                      width: '100vw',
                      filter: isXsOrMd ? 'grayscale(0%)' : 'none',
                      transition: 'filter 0.5s ease',
                    }}
                  />
                </Item>
              </Fade>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              container
              justifyContent={isXsOrMd ? "center" : "flex-end"}  // Center horizontally
              alignItems={isXsOrMd ? "flex-start" : "center"}     // Center vertically
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: '100%',
                height: '100vh',
                zIndex: fadeRevealIntro >= 3 ? 1 : 0,
                mr: isXsOrMd ? 0 : 10,
                mt: isXsOrMd ? 10 : 0
              }}
            >
              <Bounce right when={fadeRevealIntro >= 3}>
                <Item sx={{ justifyContent: 'flex-end', }}>
                  <Typography variant={isWideScreen ? "h3" : "h5"} sx={{
                    display: 'flex',
                    justifyContent: isXsOrMd ? "center" : "flex-end",
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'Courier New',
                  }}>I'm</Typography>
                  <Typography variant={isWideScreen ? "h2" : "h4"} sx={{
                    display: 'flex',
                    justifyContent: isXsOrMd ? "center" : "flex-end",
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'Courier New',
                  }}>Tot Worasrivisal</Typography>
                  <br />
                  <Typography variant={isWideScreen ? "h5" : "body2"} sx={{
                    display: 'flex',
                    justifyContent: isXsOrMd ? "center" : "flex-end",
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'Courier New',
                  }}>Blockchain Developer</Typography>
                  <Typography variant={isWideScreen ? "h5" : "body2"} sx={{
                    display: 'flex',
                    justifyContent: isXsOrMd ? "center" : "flex-end",
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'Courier New',
                  }}>Certified AWS Cloud Practitioner</Typography>
                </Item>

              </Bounce>
            </Grid>
          </Grid>
        </Fade>
      </div>
      <div className='about'>
        <Fade onReveal={() => updateSequence(0, [`Passionate Blockchain Developer with skills in backend and smart contract development. \nExtensive knowledge of blockchain concepts and experience in both permissionless and permissioned blockchain. \nA fast learner with a great work ethic and the ability to work independently or with others.`])}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
            {sequence[0] && (
              <CodeEditorTyping width="95vw" header="About Me" sequence={sequence[0]} />
            )}
          </Box>
        </Fade>

        <Fade onReveal={() => updateSequence(1, [`August 2017 — June 2021 \nBachelor of Engineering in Computer Engineering, King Mongkut's Institute of Technology Ladkrabang, Bangkok \n  • Graduated with a GPA of 3.13 and researching reinforcement learning for controlling traffic signals for my thesis.`])}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
            {sequence[1] && (
              <CodeEditorTyping width="95vw" header="Education" sequence={sequence[1]} />
            )}
          </Box>
        </Fade>



      </div>
    </div>
  );
}

export default App;
