import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider, alpha, getContrastRatio } from "@mui/material/styles";
import { Link } from "react-scroll";
import "../css/Navbar.css";


function Navbar() {
    const [isWideScreen, setIsWideScreen] = useState(true);
    const [activeSection, setActiveSection] = useState("");

    const theme = createTheme({
        typography: {
            h6: {
                color: "white", // Set the color to white
            },
        },
        palette: {
            violet: {
                main: alpha("#7F00FF", 0.7),
                light: alpha("#7F00FF", 0.5),
                dark: alpha("#7F00FF", 0.9),
                contrastText: getContrastRatio(alpha("#7F00FF", 0.7), "#fff") > 4.5 ? "#fff" : "#111",
            },
            darkGunmetal: {
                main: "#1A2634"
            },
            white: {
                main: alpha("#FFFFFF", 0.7),
                light: alpha("#FFFFFF", 0.5),
                dark: alpha("#FFFFFF", 0.9),
                contrastText: getContrastRatio(alpha("#FFFFFF", 0.7), "#fff") > 4.5 ? "#fff" : "#111",
            },
            bronze: {
                main: alpha("#c9753d", 1),
                light: alpha("#c9753d", 0.5),
                dark: alpha("#c9753d", 0.9),
                contrastText: getContrastRatio(alpha("#c9753d", 0.7), "#fff") > 4.5 ? "#fff" : "#111",
            },

        },
    });

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 768); // You can adjust this threshold
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <nav>
            <ThemeProvider theme={theme}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar color="darkGunmetal" sx={{ "min-height": '64px' }}>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', "min-height": '64px' }}>
                            {/* Typography */}
                            <Typography variant="h6" noWrap component="div" sx={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                paddingRight: '30px',
                                mr: 1
                            }}>
                                {"My Portfolio"}
                            </Typography>

                            {/* Centered button container */}
                            {isWideScreen && (
                                <div className="navbarButton" sx={{ justifyContent: "center" }}>
                                    <Link to="introduction" spy={true} smooth={true} duration={500} onSetActive={() => setActiveSection("introduction")}>
                                        <Button
                                            color="bronze"
                                            variant={activeSection === "introduction" ? "outlined" : "text"}>introduction</Button>
                                    </Link>
                                    <Link to="about" spy={true} smooth={true} duration={500} offset={-64} onSetActive={() => setActiveSection("about")}>
                                        <Button
                                            color="bronze"
                                            variant={activeSection === "about" ? "outlined" : "text"}>about</Button>
                                    </Link>
                                    {/* <Link to="introduction" spy={true} smooth={true} duration={500}>
                                        <Button color="bronze">Nav1</Button>
                                    </Link>
                                    <Link to="introduction" spy={true} smooth={true} duration={500}>
                                        <Button color="bronze">Nav1</Button>
                                    </Link>
                                    <Link to="introduction" spy={true} smooth={true} duration={500}>
                                        <Button color="bronze">Nav1</Button>
                                    </Link>
                                    <Link to="introduction" spy={true} smooth={true} duration={500}>
                                        <Button color="bronze">Nav1</Button>
                                    </Link> */}
                                </div>
                            )}

                            {/* IconButton */}
                            <IconButton size="medium" color="white" sx={{ ml: 1 }}>
                                <LinkedInIcon color="white" fontSize="large" onClick={() => {
                                    window.open("https://www.linkedin.com/in/tot-worasrivisal-488450212/", "_blank");
                                }} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
            </ThemeProvider>
        </nav>
    );
}

export default Navbar;
